.spinOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent white */
  z-index: 1000; /* Ensure it's above everything else */
  display: flex;
  justify-content: center;
  align-items: center;
}