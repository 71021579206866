.menuContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(16, 28, 45);
  padding: 0 1.25rem;
  border-top: 0.25px solid rgba(147, 134, 222, 0.5);
}

.MainMenu {
  list-style-type: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}


.menuContainer li {
  margin-right: 1.25rem;
  padding:1rem 0;
}

.menuText {
  color: #fff;
}

.selected {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  font-weight: bold;
}

.menuWrapper {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.menu {
  width: 100%;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: nowrap;
}
.menu p{
  color:#fff;
}
.arrowLeft, .arrowRight {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  cursor: pointer;
  z-index: 1;
}
.arrowLeft {
  transform: translateX(-50%);
  left: 0;
}

.arrowRight {
  right: 0;
  transform: translateX(25%);
}

.arrowLeft:hover, .arrowRight:hover {
  background-color: rgba(0, 0, 0, 0.7);
}