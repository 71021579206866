.listingFrame {
  overflow-y: auto;
  padding: 1rem;
}

.buttonsFrame {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.listingHeader {
  border-bottom: 1px solid #ddd;
  padding: 0.625rem; /* Equivalent to 10px */
}

.listingPrice::before {
  content: '$';
}

.amount {
  color: red;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.625rem; /* Equivalent to 10px */
}

.imageContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; /* Added space below the image container */
}

.imageContainer img {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the entire area */
  border-radius: 0.3125rem; /* Equivalent to 5px */
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1); /* Equivalent to 2px 8px */
  border: 0.0625rem solid #e22e2e; /* Equivalent to 1px */
}

.imageWrapper {
  text-align: center;
  width: 100%;
}

.imageWrapper h5 {
  margin-bottom: 0.5rem; /* Equivalent to 8px */
}

@media (min-width: 768px) {
  .listingFrame {
    padding: 1.5rem; /* Equivalent to 24px */
  }

  .imageContainer {
    flex-direction: row;
  }

  .imageWrapper {
    flex: 1;
    padding: 0 1rem; /* Equivalent to 16px */
  }
}

@media (min-width: 1024px) {
  .listingFrame {
    padding: 2rem; /* Equivalent to 32px */
  }
}
